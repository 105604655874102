import { defineStore } from 'pinia'
import cloneDeep from 'lodash.clonedeep'
import { useAirportStore } from '@/Stores/Airport.js'

export const useOffersStore = defineStore('offers', () => {
    const router = useRouter()
    const route = useRoute()

    const loading = reactive({
        items: true,
        filter: true,
        loadMore: false
    })

    const total = ref(0)
    const totalPages = ref(0)

    const items = ref([])
    const filters = ref([])

    const params = reactive({
        q: '',
        startDate: '',
        endDate: '',
        duration: '',
        adults: 2,
        children: [],
        region: '',
        regionGroup: '',
        locationName: '',
        location: '',
        productType: 'pauschal',
        subType: '',
        departureAirport: [],
        page: 1,
        gid: '',
        filter_category: [],
        filter_location: [],
        filter_rating: [],
        filter_serviceType: [],
        filter_roomType: [],
        filter_tourOperator: [],
        filter_name: '',
        sort: 'price;asc'
    })

    const disableSearch = computed(() => {
        if (params.startDate == '' || params.endDate == '') {
            return true
        }
        return false
    })

    const enableResetFilter = computed(() => {
        return params.filter_category.length || params.filter_location.length || params.filter_rating.length || params.filter_serviceType.length || params.filter_name || params.filter_tourOperator
    })

    const resetFilter = () => {
        params.filter_roomType = params.filter_category = params.filter_location = params.filter_rating = params.filter_serviceType = []
        params.filter_name = ''
    }

    /* watch(() => [params.filter_category, params.filter_location, params.filter_rating, params.filter_serviceType, params.filter_roomType, params.sort], () => {
         params.page = 1;
     }, { deep: true });*/

    watch(
        () => [params.filter_category, params.filter_location, params.filter_rating, params.filter_serviceType, params.sort, params.filter_roomType, params.filter_tourOperator],
        () => {
            filterData()
        },
        { deep: true }
    )

    watch(
        () => [params.regionGroup, params.region, params.location],
        () => {
            useAirportStore().loadData(params)
        },
        { deep: true }
    )

    const filterData = () => {
        params.page = 1

        loading.enableResults = loading.items = true

        return fetchData()
            .then((response) => {
                items.value = Object.values(response.data.items)
                total.value = response.data.totalResultCount
                totalPages.value = response.data.totalPages
            })
            .then(() => {
                loading.items = false
            })
    }

    const search = (target = '_self') => {
        const routeData = router.resolve({ name: 'offers', query: cloneDeep(params) })
        window.open(routeData.href, target)
    }

    const update = () => {
        loading.enableResults = loading.items = loading.filter = true

        return fetchData()
            .then((response) => {
                items.value = Object.values(response.data.items)
                total.value = response.data.totalResultCount
                totalPages.value = response.data.totalPages
                filters.value = response.data.filters
            })
            .then(() => {
                loading.filter = loading.items = false
            })
    }

    const loadMore = () => {
        params.page++

        loading.loadMore = false

        return fetchData()
            .then((response) => {
                items.value.push(...Object.values(response.data.items))
            })
            .then(() => {
                loading.loadMore = false
            })
    }

    const fetchData = () => {
        return axios.get('/api/offers', {
            params: params
        })
    }

    onMounted(() => {
        router.isReady().then(() => {
            const queryParams = route.query
    
            Object.keys(params).forEach((key) => {
                if (queryParams[key] !== undefined) {
                    let value = queryParams[key]
    
                    // Convert values based on the expected type
                    if (typeof params[key] === 'number') {
                        value = Number(value) || params[key] // Ensure number conversion
                    } else if (Array.isArray(params[key])) {
                        value = Array.isArray(value) ? value : [value] // Ensure array format
                    }
    
                    params[key] = value
                }
            })
        })
    })

    return {
        params,
        items,
        filters,
        disableSearch,
        enableResetFilter,
        loading,
        total,
        totalPages,
        filterData,
        resetFilter,
        search,
        loadMore,
        update,
        fetchData
    }
})
