import { defineStore } from 'pinia'
import { useAxios } from '@/Helpers/useAxios.js'
import cloneDeep from 'lodash.clonedeep'
import { useAirportStore } from '@/Stores/Airport.js'

export const useOfferDatesStore = defineStore('offerDates', () => {
    const router = useRouter()
    const route = useRoute()

    const loading = reactive({
        items: true,
        filter: true,
        loadMore: false,
        enableResults: false
    })

    const pages = ref(0)
    const items = ref([])
    const filters = ref([])

    const params = reactive({
        gid: '',
        startDate: '',
        endDate: '',
        adults: 2,
        children: [],
        region: '',
        regionGroup: '',
        locationName: '',
        location: '',
        productType: '',
        departureAirport: [],
        page: 1,
        filter_roomType: [],
        filter_serviceType: [],
        filter_entryPoint: [],
        filter_tourOperator: [],
        filter_maxConnections: '',
        sort: 'price;asc'
    })

    const enableResetFilter = computed(() => {
        return params.filter_roomType.length || params.filter_serviceType.length || params.filter_entryPoint || params.filter_maxConnections || params.filter_tourOperator
    })

    const resetFilter = () => {
        params.filter_roomType = []
        params.filter_serviceType = []
        params.filter_maxConnections = []
        params.filter_entryPoint = []
        params.filter_tourOperator = []
    }

    watch(
        () => [params.filter_roomType, params.filter_entryPoint, params.filter_serviceType, params.filter_maxConnection, params.filter_tourOperator],
        () => {
            params.page = 1

            if (loading.items == false) {
                filterData()
            }
        },
        { deep: true }
    )

    const filterData = () => {
        params.page = 1

        loading.items = true

        return fetchData()
            .then((response) => {
                items.value = Object.values(response.data.results)
                pages.value = response.data.totalPages
            })
            .then(() => {
                loading.items = false
            })
    }

    const loadMore = () => {
        params.page++

        loading.loadMore = false

        return fetchData()
            .then((response) => {
                items.value.push(...Object.values(response.data.results))
            })
            .then(() => {
                loading.loadMore = false
            })
    }

    const search = () => {
        loading.enableResults = loading.items = loading.filter = true

        const query = cloneDeep(params)
        router.push({ path: '', query: query })

        return fetchData()
            .then((response) => {
                items.value = response.data.results
                pages.value = response.data.totalPages
                filters.value = response.data.filters
            })
            .then(() => {
                loading.filter = loading.items = false
            })
    }

    const fetchData = () => {
        return axios.get('/api/dates', {
            params: params
        })
    }

    onMounted(() => {
        router.isReady().then(async () => {
            _.assign(params, route.query)
            search()

            useAirportStore().loadData(params)
        })
    })

    return {
        params,
        items,
        filters,
        enableResetFilter,
        loading,
        pages,
        loadMore,
        resetFilter,
        search
    }
})
